import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { SharedFunctionService } from '../shared.function.service';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import * as Highcharts from 'highcharts';
import { KiwiMonsterCompareResult } from 'src/app/models/kiwimonster/kiwimonster-compare-result.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterCompareService extends ComponentBaseClass {

  constructor (
    private sharedFunction: SharedFunctionService,
    private datePipe: DatePipe
  ) {
    super();
  }

  onInit(): void {

  }

  updateClientResults(currentClientList: KiwiMonsterClient[], responseClientList: KiwiMonsterClient[]): void {
    responseClientList.forEach(responseClient => {
      let matchingClient = currentClientList.find(currentClient => currentClient.Id === responseClient.Id);
      if (matchingClient) {
        matchingClient.Results = responseClient.Results;
        matchingClient.DeathClock = responseClient.DeathClock;
      }
    });
  }

  setNumberOfProspectiveFunds(clientList: KiwiMonsterClient[]): void {
    clientList.forEach(c => {
      c.NumberOfProspectiveFunds = c.Setting.DoYouHaveKiwiSaver ? c.Results.length - 1 : c.Results.length;
      c.OnlyOneProspectiveFund = !c.Setting.DoYouHaveKiwiSaver && c.Results.length === 1;
    })
  } 

  invokeGenerateSingleBarChart(client: KiwiMonsterClient, clientId: number): void {
    client.Results.forEach((result, index) => {

      let maxEstimatedKSBalance = this.getMaxData('EstimatedKSBalance', client.Results);
      let minEstimatedKSBalance = this.getMaxData('EstimatedKSBalance', client.Results);
      this.generateSingleBarChart(result.EstimatedKSBalance, maxEstimatedKSBalance, minEstimatedKSBalance, `${ result.SchemeName } - ${ result.FundName }`, `estimatedKSBalanceBarChartContainer-${ clientId }-${ index }`, index, 'currency', client.Setting.DoYouHaveKiwiSaver);

      let maxEstimatedWeeklyRetirementIncome = this.getMaxData('EstimatedWeeklyRetirementIncome', client.Results);
      let minEstimatedWeeklyRetirementIncome = this.getMaxData('EstimatedWeeklyRetirementIncome', client.Results);
      this.generateSingleBarChart(result.EstimatedWeeklyRetirementIncome, maxEstimatedWeeklyRetirementIncome, minEstimatedWeeklyRetirementIncome, `${ result.SchemeName } - ${ result.FundName }`, `estimatedWeeklyRetirementIncomeBarChartContainer-${ clientId }-${ index }`, index, 'currency', client.Setting.DoYouHaveKiwiSaver);

      let maxRiskRewardIndicatorCode = this.getMaxData('RiskRewardIndicatorCode', client.Results);
      let minRiskRewardIndicatorCode = this.getMaxData('RiskRewardIndicatorCode', client.Results);
      this.generateSingleBarChart(result.RiskRewardIndicatorCode, maxRiskRewardIndicatorCode, minRiskRewardIndicatorCode, `${ result.SchemeName } - ${ result.FundName }`, `riskRewardIndicatorCodeBarChartContainer-${ clientId }-${ index }`, index, 'number', client.Setting.DoYouHaveKiwiSaver);

      let maxPastYearReturnNet = this.getMaxData('PastYearReturnNet', client.Results);
      let minPastYearReturnNet = this.getMaxData('PastYearReturnNet', client.Results);
      let pastYearReturnNetMBIEReportDate = this.datePipe.transform(result.PastYearReturnNetMBIEReportDate, 'dd-MMM-yyyy') || 'N/A';
      if(result.PastYearReturnNetMBIEReportDate == null){
        result.PastYearReturnNet = 0;
        this.generateSingleBarChart(result.PastYearReturnNet, maxPastYearReturnNet, minPastYearReturnNet, `${ result.SchemeName } - ${ result.FundName }`, `pastYearReturnNetBarChartContainer-${ clientId }-${ index }`, index, 'percentage', client.Setting.DoYouHaveKiwiSaver);
      }else{
        this.generateSingleBarChart(result.PastYearReturnNet, maxPastYearReturnNet, minPastYearReturnNet, `${ result.SchemeName } - ${ result.FundName }`, `pastYearReturnNetBarChartContainer-${ clientId }-${ index }`, index, 'percentage', client.Setting.DoYouHaveKiwiSaver, true, `Annual return period end date ${pastYearReturnNetMBIEReportDate}`, 2);
      }
      

      let maxAverageFiveYearReturnNet = this.getMaxData('AverageFiveYearReturnNet', client.Results);
      let minAverageFiveYearReturnNet = this.getMaxData('AverageFiveYearReturnNet', client.Results);
      this.generateSingleBarChart(result.AverageFiveYearReturnNet, maxAverageFiveYearReturnNet, minAverageFiveYearReturnNet, `${ result.SchemeName } - ${ result.FundName }`, `averageFiveYearReturnNetBarChartContainer-${ clientId }-${ index }`, index, 'percentage', client.Setting.DoYouHaveKiwiSaver);

      let maxPerformanceSinceInceptionReturnNet = this.getMaxData('PerformanceSinceInceptionReturnNet', client.Results);
      let minPerformanceSinceInceptionReturnNet = this.getMaxData('PerformanceSinceInceptionReturnNet', client.Results);
      this.generateSingleBarChart(result.PerformanceSinceInceptionReturnNet, maxPerformanceSinceInceptionReturnNet, minPerformanceSinceInceptionReturnNet, `${ result.SchemeName } - ${ result.FundName }`, `performanceSinceInceptionReturnNetBarChartContainer-${ clientId }-${ index }`, index, 'percentage', client.Setting.DoYouHaveKiwiSaver);

      let maxTotalAnnualFundCharge = this.getMaxData('TotalAnnualFundCharge', client.Results);
      let minTotalAnnualFundCharge = this.getMaxData('TotalAnnualFundCharge', client.Results);
      this.generateSingleBarChart(result.TotalAnnualFundCharge, maxTotalAnnualFundCharge, minTotalAnnualFundCharge, `${ result.SchemeName } - ${ result.FundName }`, `totalAnnualFundChargeBarChartContainer-${ clientId }-${ index }`, index, 'percentage', client.Setting.DoYouHaveKiwiSaver);

      let maxFundTotalValue = this.getMaxData('FundTotalValue', client.Results);
      let minFundTotalValue = this.getMaxData('FundTotalValue', client.Results);
      this.generateSingleBarChart(result.FundTotalValue, maxFundTotalValue, minFundTotalValue, `${ result.SchemeName } - ${ result.FundName }`, `fundTotalValueBarChartContainer-${ clientId }-${ index }`, index, 'currency', client.Setting.DoYouHaveKiwiSaver);

      let maxNumberOfInvestors = this.getMaxData('NumberOfInvestors', client.Results);
      let minNumberOfInvestors = this.getMaxData('NumberOfInvestors', client.Results);
      this.generateSingleBarChart(result.NumberOfInvestors, maxNumberOfInvestors, minNumberOfInvestors, `${ result.SchemeName } - ${ result.FundName }`, `numberOfInvestorsBarChartContainer-${ clientId }-${ index }`, index, 'number', client.Setting.DoYouHaveKiwiSaver);

    });
  }

  private generateSingleBarChart(data: number, maxData: number, minData: number, name: string, containerId: string, index: number, type: string, hasExistingFund: boolean, xAxisLabel?: boolean, xAxisLabelValue?:string, toFixedNumber: number = 1): void {
    const chartData = [{
      y: data < 0 ? 0 : data,
      name: name,
      color: hasExistingFund && index === 0 ? '#e5764c' : '#99CCFF',
    }];

    Highcharts.chart(containerId, {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        height: 200,
        width: 200,
        style: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: xAxisLabel?[xAxisLabelValue || '']:[''],
        labels: {
          enabled: xAxisLabel 
        },
        gridLineWidth: 0, 
        lineWidth: 0 
      },
      yAxis: {
        title: {
          text: null 
        },
        labels: {
          enabled: false 
        },
        min: maxData < 0 ? minData : 0,
        max: maxData < 0 ? 20: maxData, 
        gridLineWidth: 0
      },
      tooltip: {
        followPointer: true,
        hideDelay: 200, 
        outside: true,
        formatter: function () {
          return name;
        }
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            style: {
              fontSize: '13px'
            },
            formatter: function () {
              if (type === 'percentage' && data === 0) {
                return `<strong>N/A</strong>`;
              } else {
                let valueString = type === 'currency' ? `$${ Number(data.toFixed(0)).toLocaleString() }` : type === 'percentage' ? `${ data.toFixed(toFixedNumber) }%` : `${ Number(data.toFixed(0)).toLocaleString() }`;
                return data < 0 ? `<strong class="text-danger">${ valueString }</strong>` : `<strong>${ valueString }</strong>`;
              }
            },
            overflow: "allow",
            crop: false,

          },
          pointWidth: 40,
          minPointLength: data <= 0 ? 0 : 5 
        }
      },
      series: [{
        data: chartData,
        type: 'column',
        showInLegend: false
      }],
      legend: {
        itemStyle: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
      }
    });

  } 

  private getMaxData(propertyName: string, results: KiwiMonsterCompareResult[]): number {
    let maxData = -Infinity;
    for (let result of results) {
      if (result[propertyName] > maxData) {
        maxData = result[propertyName];
      }
    }
    return maxData;
  }

  private getMinData(propertyName: string, results: KiwiMonsterCompareResult[]): number {
    let minData = Infinity; 
    for (let result of results) {
      if (result[propertyName] < minData) {
        minData = result[propertyName];
      }
    }
    return minData;
  }
  
  invokeGenerateClientLineCharts(client: KiwiMonsterClient): void {
    if (client.Results && client.Results.length > 0) {
      // get client's max EstimateReturnToRetirement from all results
      let maxData: number = this.getMaxData('EstimateReturnToRetirementMaxTotalBalance', client.Results);
      
      client.Results.forEach((result, index) => {
        if (result.HaveValueEstimateReturnToEndTotalBalance || result.HaveValueEstimateReturnToRetirementTotalBalance) {
          this.generateSingleLineChart(result.EstimateTotalBalanceWithAge, maxData, `totalBalanceChartContainer-${ client.Id }-${ result.Id }`, client.Setting.DoYouHaveKiwiSaver, index);
        }
      });
    }
  }
  
  private generateSingleLineChart(data: [number, number][], maxData: number, containerId: string, hasExistingFund: boolean, resultIndex: number): void {
    let yData = data.map(point => point[1]);
    let maxY = this.sharedFunction.getMaxValue(yData);
    let maxIndex = yData.indexOf(maxY);
    let maxPoint = data[maxIndex];
    let chart = Highcharts.chart(containerId, {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'line',
        height: 200,
        style: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
      title: {
        text: null,
      },
      xAxis: {
        title: {
          text: null
        },
        tickPositioner: function () {
          let start = data[0][0];
          let end = data[data.length - 1][0];
          return [start, maxPoint[0], end];
        },
        labels: {
          // issue: not working
          autoRotation:[-45,-90],
          autoRotationLimit: 50,
        }
      },
      yAxis: {
        title: {
          text: null
        },
        max: maxData * 1.1,
        labels: {
          formatter: function () {
            const value = this.value;
            // Convert the value to 'K' format and prepend '$'
            const formattedValue = (value / 1000).toLocaleString();
            return `$${ formattedValue }K`;
          }
        },
      },
      tooltip: {
        hideDelay: 200,
        outside: true,
        formatter: function () {
          let formattedY = Number((this.y / 1000).toFixed(0)).toLocaleString();
          return `<b>Age:</b> ${ this.x }<br><b>Balance:</b> $${ formattedY }K`;
        }
      },
      series: [{
        data: data.map((point, index) => ({
          x: point[0],
          y: point[1],
          marker: {
            enabled: index === maxIndex,
            symbol: 'circle',
            radius: 5
          }
        })),
        type: 'line',
        showInLegend: false,
        color: hasExistingFund && resultIndex === 0 ? '#E5764C' : '#99CCFF',
        marker: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.y === maxY) {
              return `$${ Number((this.y / 1000).toFixed(0)).toLocaleString() }K`;
            }
            return '';
          },
          overflow: "allow",
          crop: false,
        }
      }],
      legend: {
        itemStyle: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
      },
    });
    
    // fixed issue: line chart can not auto change width when resize from large to small
    window.addEventListener('resize', () => {
      if (chart && chart.chartWidth > 0) {
        chart.destroy();
      }
    })
    
  }
  
  setEstimateReturnToData(clients: KiwiMonsterClient[]): void {
    clients.forEach(client => {
      if (client.Results && client.Results.length > 0) {
        client.Results.forEach(result => {
          // get the last total balance of EstimateReturnToRetirement
          result.EstimateReturnToRetirementMaxTotalBalance = KiwiMonsterCompareResult.getERTRMaxTotalBalance(result.EstimateReturnToRetirement);

          // get line chart data
          result.EstimateTotalBalanceWithAge = KiwiMonsterCompareResult.getEstimateTotalBalanceWithAge(result);

          // check if all retirement total balance values are 0
          result.HaveValueEstimateReturnToRetirementTotalBalance = KiwiMonsterCompareResult.getHaveValueERTRTotalBalance(result.EstimateReturnToRetirement);
          // check if all to end total balance values are 0
          result.HaveValueEstimateReturnToEndTotalBalance = KiwiMonsterCompareResult.getHaveValueERTETotalBalance(result.EstimateReturnToEnd);
        });
      }
      
      // check if all results' estimate return to retirement and to end values are 0
      client.HasEstimateReturnTotalBalance = KiwiMonsterClient.getHasEstimateReturnTotalBalance(client.Results);
    });
  }
  
  private generateSingleClientAllResultsLineChart(categoriesData: string[], seriesData: any[], containerId: string, hasExistingFund: boolean): void {
    let chart = Highcharts.chart(containerId, {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'line',
        height: 500,
        style: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
        backgroundColor: 'rgba(255, 255, 255, 0)',
      },
      title: {
        text: null,
      },
      xAxis: {
        title: {
          text: null
        },
        categories: categoriesData,
        labels: {
          step: 2
        }
      },
      yAxis: {
        title: {
          text: null
        },
        labels: {
          formatter: function () {
            const value = this.value;
            // Convert the value to 'K' format and prepend '$'
            const formattedValue = (value / 1000).toLocaleString();
            return `$${ formattedValue }K`;
          }
        },
      },
      tooltip: {
        hideDelay: 200,
        outside: true,
        formatter: function () {
          let formattedY = Number((this.y / 1000).toFixed(0)).toLocaleString();
          return `<b>Age:</b> ${ this.x }<br><b>Balance:</b> $${ formattedY }K`;
        }
      },
      series: seriesData.map((series, index) => {

        // Find the maximum Y value point
        const maxPoint = series.data.reduce((prev, curr) => (curr > prev ? curr : prev), -Infinity);
        const maxIndex = series.data.indexOf(maxPoint);

        let color;
        let colors = ['#99CCFF', '#e6de59', '#64b86a'];
        if (hasExistingFund) {
          color = index === 0 ? '#E5764C' : colors[(index - 1) % colors.length];
        } else {
          color = colors[index % colors.length];
        }
        
        return {
          ...series,
          color: color,
          data: series.data.map((point, pointIndex) => ({
            y: point,
            marker: pointIndex === maxIndex ? {
              enabled: true,
            } : { enabled: false, },
            dataLabels: pointIndex === maxIndex ? {
              enabled: true,
              formatter: function () {
                return `$${ Number((this.y / 1000).toFixed(0)).toLocaleString() }K`;
              },
            } : {}
          })),
          marker: {
            enabled: true
          }
        };
      }),
      legend: {
        itemStyle: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px',
        },
        itemDistance: 80,
        itemMarginTop: 10,
      },
    });
    // fixed issue: line chart can not auto change width when resize from large to small
    window.addEventListener('resize', () => {
      if (chart && chart.chartWidth > 0) {
        chart.destroy();
      }
    });

  }
  
  private setAllResultsLineChartSeriesData(client: KiwiMonsterClient): any[] {
    let allResultsLineChartSeriesData = [];
    if (client.Results && client.Results.length > 0) {
      client.Results.forEach(result => {
        if (result.HaveValueEstimateReturnToEndTotalBalance || result.HaveValueEstimateReturnToRetirementTotalBalance) {
          let newChartData = {
            name: `${ result.SchemeName } - ${ result.FundName }`,
            data: result.EstimateTotalBalanceWithAge.map((item:[number,number])=> item[1])
          };
          allResultsLineChartSeriesData.push(newChartData);
        }
      });
    }
    return allResultsLineChartSeriesData;
  }
  
  private setAllResultsLineChartCategoriesData(client: KiwiMonsterClient): string[]{
    let allResultsLineChartCategoriesData = [];
    if (client.Results && client.Results.length > 0) {
      let result = client.Results.find(r => r.EstimateTotalBalanceWithAge.length > 0);
      allResultsLineChartCategoriesData = result.EstimateTotalBalanceWithAge.map((item: [number, number]) => item[0].toString());
    }
    return allResultsLineChartCategoriesData;
  }
  
  invokeGenerateClientAllResultsLineChart(client: KiwiMonsterClient): void {
    if (client.HasEstimateReturnTotalBalance && !client.OnlyOneProspectiveFund) {
      this.generateSingleClientAllResultsLineChart(this.setAllResultsLineChartCategoriesData(client), this.setAllResultsLineChartSeriesData(client), `totalBalanceAllResultsChartContainer-${ client.Id }`, client.Setting.DoYouHaveKiwiSaver);
    }
  }
}